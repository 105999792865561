import { Component } from '@angular/core';

// declare var AOS: any;
// declare var Glightbox: any;
// declare var Isotope: any;
// glightbox
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FaConfig } from '@fortawesome/angular-fontawesome';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(faConfig: FaConfig) {
  	faConfig.defaultPrefix = 'far';
  }
}
